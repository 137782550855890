<script lang="ts">
	import { RequestBuilder } from "@lernetz/request";
	import Sketch from "@lernetz/svelte-svg-sketch/Sketch.svelte";
    import type { ActiveItem } from "@lernetz/svelte-svg-sketch";
	import Icon from "@lernetz/svelte-icon/Icon.svelte";

    let color = "#ff00ff";

    /* 
    "workspaces": [
        "../../lfw/svelte-components/src/svg-sketch"
    ]
   */
  
	let bg = { name: "spielfeld_beach-06", width: 671.62, height: 449.75 };
	/* let items = [
	  { name: "arrow-up-fill", x: 0.4, y: 0.3, width: 0.2, height: 0.1, rotation: 45, scaleX: 1, scaleY: 1 },
	  { name: "crosshair-line", x: 0.1, y: 0.1, width: 0.2, height: 0.1, rotation: 0, scaleX: 1, scaleY: 1 },
	  { name: "home-2-line", x: 0.8, y: 0.6, width: 0.2, height: 0.1, rotation: 0, scaleX: 1, scaleY: 1 },
	]; */

    
    let items = [
	  /* { name: "re_ball", x: 0.4, y: 0.3, width: 0.05, height: 0.1, rotation: 45, scaleX: 1, scaleY: 1, color: "#0f0" },
	  { name: "re_kasten", x: 0.1, y: 0.1, width: 0.05, height: 0.1, rotation: 0, scaleX: 1, scaleY: 1, color: "#0f0" },
	  { name: "home-2-line", x: 0.8, y: 0.6, width: 0.05, height: 0.1, rotation: 0, scaleX: 1, scaleY: 1, color: "#0f0" },
	  { name: "arrow-long", x: 0.1, y: 0.8, width: 0.125, height: 0.05, rotation: 0, scaleX: 1, scaleY: 1, color: "#0f0" }, */
	];

	//let icons = ['arrow-up-fill', 'crosshair-line', 'home-2-line'];
    /* let icons = [
        { name: "arrow-up-fill", width: 40, height: 40, props: {w: 0.4, h: 0.2}},
        { name: "arrow-up-fill", width: 30, height: 30, props: {w: 0.2, h: 0.1}},
        { name: "crosshair-line", width: 30, height: 30, props: {w: 0.2, h: 0.1}},
        { name: "home-2-line", width: 30, height: 30, props: {w: 0.2, h: 0.1}},
        { name: "crosshair-line", width: 30, height: 30, props: {w: 0.2, h: 0.1}},
        { name: "arrow-long", width: 160, height: 40, props: {w: 0.2, h: 0.1}},
    ] */
    
    let icons = [
        { name: "re_ball", width: 40, height: 40, props: {w: 0.1, h: 0.2}},
        { name: "re_kasten", width: 60, height: 30, props: {w: 0.1, h: 0.08}},
        { name: "re_ballwagen_kontur_Zeichenfläche_1", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "re_ballwagen_fläche_Zeichenfläche 1 Kopie", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "person_ohne_ball_mit_Schrift_Calibri-25", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "person_ohne_ball_mit_Schrift_Calibri-26", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "person_ohne_ball_mit_Schrift_Calibri-27", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "person_mit_ball_2-25", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "person_mit_ball_2-26", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "person_mit_ball_2-27", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "rot_person_person_ohne_ball_ohne_Schrtif", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "png_person_person_ohne_ball_ohne_Schrtif_Zeichenfläche1", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "rot_person_person_mit_ball_mit_Schrtif-25", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "rot_person_person_mit_ball_mit_Schrtif-26", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "rot_person_person_mit_ball_mit_Schrtif-27", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "rot_person_person_mit_ball_mit_Schrtif-28", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "rot_person_person_ohne_ball_mit_Schrtif_Zeichenfläche 1 Kopie 8-25", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "rot_person_person_ohne_ball_mit_Schrtif_Zeichenfläche 1 Kopie 8-26", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "rot_person_person_ohne_ball_mit_Schrtif_Zeichenfläche 1 Kopie 8-27", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
        { name: "rot_person_person_ohne_ball_mit_Schrtif_Zeichenfläche 1 Kopie 8-28", width: 30, height: 30, props: {w: 0.1, h: 0.1}},
    ]
   
    
	let active: ActiveItem;
	let tool = "edit";
  
	let base = new RequestBuilder("/icons.svg");
  </script>

  
  <div class="mt-40 mb-20">
	<button on:click ={() => tool = "add"}><Icon request={base} name="add-circle-line" class="w-20 h-20 {tool == 'add' ? 'fill-blue-500' : 'fill-black'}"/></button>
	<button on:click ={() => tool = "edit"}><Icon request={base} name="pencil-line" class="w-20 h-20 {tool == 'edit' ? 'fill-blue-500' : 'fill-black'}"/></button>
	<button on:click ={() => tool = "color"}><Icon request={base} name="palette-line" class="w-20 h-20 {tool == 'color' ? 'fill-blue-500' : 'fill-black'}"/></button>
    <input type="color" bind:value={color}>
</div>


    <div class="flex">

        <div class="border-black border">
            <Sketch {items} {bg} {base} {active} {tool} {color}/>
        </div>
    
    
        <div class="w-[200px] h-[200px] ml-10 p-10 bg-slate-200 overflow-auto">
            {#each icons as icon}
                <button class="w-full {active == icon ? 'bg-blue-500' : ''}" on:click={() => active = icon}> <!-- {active.name == icon.name ? 'bg-blue-500' : ''} -->
                    <Icon request={base} name={icon.name} class="py-4 px-4" style="width: {icon.width}px; height: {icon.height}px;" /> <!-- w-20 h-20 -->
                </button>
            {/each}
        </div>
    </div>

  <!-- 
    "workspaces": [
    "../../lfw/svelte-components/src/svg-sketch"
  ]
   -->


   <!-- 
    ,
    "@lernetz/svelte-svg-sketch": "^1.0.0"
    -->
    



  <!--   <svg width="600px" height="400px">
        <defs>
            <marker id="arrow" markerWidth="10" markerHeight="10" refX="0" refY="3" orient="auto" markerUnits="strokeWidth">
                <path d="M0,0 L0,6 L9,3 z" fill="#f00" transform="scale(0.5,1)" />
            </marker>
        </defs>
    
        <line x1="50" y1="50" x2="250" y2="50" transform="scale(2,1)" stroke="#000" stroke-width="5" marker-end="url(#arrow)" />
    
    </svg> -->
   
    <!-- <svg width="600px" height="400px">
        <defs>
            <marker id="arrow" markerWidth="10" markerHeight="10" refX="0" refY="3" orient="auto" markerUnits="strokeWidth">
                <path d="M0,0 L0,6 L9,3 z" fill="#f00" transform="scale(1,1)" />
            </marker>
        </defs>
    
        <line x1="50" y1="50" x2="250" y2="50" transform="scale(1,1)" stroke="#000" stroke-width="5" marker-end="url(#arrow)" />
    
    </svg> -->