import RootPage from "./pages/RootPage.svelte";
import { setupData } from "./services";


export function init( data ) {

    setupData( data );

    let root = new RootPage( {
        target: document.body,
    } );
}

