import { RequestBuilder, mapRequests } from "@lernetz/request";
import { historyMode } from "@lernetz/svelte-router";
import { defaultRequest } from "@lernetz/svelte-icon";
import { processIn } from "@lernetz/svelte-model-api/process";

export var requests: {
    icons: RequestBuilder;
};

export var data: {
    baseUrl: string,
};



export function setupData( init: { data: any, routes: any; } ) {

    requests = mapRequests( init.routes );
    data = processIn( init.data );

    historyMode( window.location.href );
    defaultRequest( requests.icons );
}
